<template>
<div class='accInfo' v-if="user">
  <div>
    <span>头像：</span>
    <img :src="user.image.url" @click="inp.click()"/>
    <input accept=".jpg,.png,.gif,.webp" type="file" hidden ref="inp" @change="changeImage"/>
  </div>
  <div>
    <span>昵称：</span><input type='text' v-model="user.nickname"/>
  </div>
  <div>
    <span>邮箱：</span>{{user.email}}
  </div>
  <PButton @click="saveUser" size="mini" :hoverActive="false" :active="btnActive.su">{{btnActive.su==true?'保存修改':'保存中...'}}</PButton>
  <PButton @click="updatePassword" size="mini" :hoverActive="false" :active="true">修改密码</PButton>
  <PButton @click="loginOutClick" size="mini" :hoverActive="false" :active="btnActive.lo">{{btnActive.lo==true?'退出登录':'正在退出...'}}</PButton>
</div>
</template>
<script>
//import {useStore} from 'vuex'
import {reactive, ref} from 'vue'
//import {updateUser} from '../../../../api/user'
//import {loginOut} from '../../../../api/login'
//import {addUserImage} from '../../../../api/userImage'
import Message from '../../../../components/library/Message'
import pButton from '../../../../components/library/pButton.vue';
import {useRouter} from 'vue-router'
import {uploadFile} from "../../../../utils/file";
import {SaveUrl} from "../../../../enum/index";
import axios from "../../../../utils/request";
export default {
  components: { pButton },
  name: 'accInfo',
  setup(){
    let inp=ref(null);
    let file=null;
    let btnActive=reactive({
      su:true,
      lo:true
    })
    //const store=useStore();
    const router=useRouter();
    let user=ref(null);
    user.value=JSON.parse(localStorage.getItem("user"));
    if(user.value==null){
      router.push("/login");
    }

    const changeImage=(e)=>{
      file=e.target.files[0];
      var reader=new FileReader();
      reader.readAsDataURL(file);
      reader.onload=function(){
        user.value.image.url=reader.result;
      }
    }
    const updatePassword=()=>{
      Message({type:'warn',text:'修改密码可在登录页面->忘记密码->重置密码'})
    }
    const updateUser=()=>{
      axios.post("/user/updateUser",user.value).then(()=>{
        localStorage.setItem("user",JSON.stringify(user.value));
        Message({type:"success",text:"保存成功！"});
      }).catch((err)=>{
        Message({type:"error",text:err.message});
      }).finally(()=>{
        btnActive.su=true;
      })
    }
    const saveUser=async ()=>{
      if(btnActive.su==false){
        return;
      }
      btnActive.su=false;
      if(file!=null){
        // let res=null;
        // res=await addUserImage(file);
        // if(res.data.status==1){
        //   user.value.imageId=res.data.data.id;
        //   user.value.userImage=res.data.data;
        // }
        uploadFile(file,SaveUrl.USER_IMAGE,"user").then((res)=>{
          user.value.imageId=res.id;
          user.value.image=res;
          updateUser();
        }).catch((err)=>{
          Message({type:"error",text:err.message});
        }).finally(()=>{
          btnActive.su=true;
        })
      }else{
        updateUser();
      }
      
      // updateUser(user.value).then(()=>{
      //   Message({type:'success',text:'保存成功！'})
      //   btnActive.su=true;
      // }).catch(function(){
      //   Message({type:'error',text:'保存失败'})
      //   btnActive.su=true;
      // })
    }

    const loginOutClick=()=>{
      if(btnActive.lo==false){
        return;
      }
      btnActive.lo=false;
      localStorage.setItem("user",null);
      router.push("/");
      // loginOut().then(()=>{
      //   store.commit('user/setLoginUser',null);
      //   router.push('/');
      // }).catch(function(){
      //   Message({type:'error',text:'请求失败，请稍后再试'})
      //   btnActive.lo=true;
      // })
    }
    //user.value=store.state.user.loginUser;
    // watch(()=>{return store.state.user.loginUser},(newVal)=>{
    //   user.value=JSON.parse(JSON.stringify(newVal));
    // },{immediate:true})
    
    return {user,updatePassword,saveUser,loginOutClick,btnActive,inp,changeImage};
  }
}
</script>

<style scoped lang='less'>
.accInfo{
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
img{
  width: 50px;
  height: 50px;
  border-radius: 25px;
  &:hover{
    cursor: pointer;
  }
}
input{
  padding-left: 10px;
  border: 1px solid #cfcdcd;
  height: 30px;
  line-height: 30px;
  &:focus {
    border-color: black;
  }
}

// li{
//   margin-bottom: 20px;
//   span{
//     font-size: 15px;
//   }
// }
</style>